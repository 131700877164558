<template>
    <p :class="classes">
        <slot />
    </p>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            required: false,
            default: () => "md",
        },
        color: {
            type: String,
            required: false,
            default: () => "default",
        },
    },
    data() {
        return {
            baseClasses: "px-8 py-4",
            sizes: {
                sm: "text-base aa",
                md: "",
            },
            colors: {
                default: "text-gray-600",
            },
        };
    },
    computed: {
        classes() {
            return `paragraph ${this.baseClasses} ${this.sizes[this.size]} ${this.colors[this.color]}`;
        },
    },
};
</script>
